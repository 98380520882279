import * as React from 'react'

import { bindBem } from '../../bem'
import { Button } from '../Button'
import { RadioGroup } from '../RadioGroup'
import RadioButton from '../RadioButton'
import { ActionsBar } from '../Modal'
import { Freq, AggregationMode } from '../../services/series'
import {
  EDIT_TITLES_DIALOG,
  AGGREGATION_LABEL,
  AGGREGATION_DIALOG,
  AGGREGATION_MODES,
} from '../../messages'

import './AggregationDialog.scss'
import { Aggregation, AggregationFrequency } from 'global'

interface IProps {
  onSave: (aggregation: Aggregation | null) => void
  minFrequency: number
  aggregation?: Aggregation
}

interface IState {
  selectedFrequency?: AggregationFrequency
  isRelaxedModeSelected: boolean
}

export class AggregationDialog extends React.Component<IProps, IState> {
  state: IState = {
    selectedFrequency: this.props.aggregation?.frequency as AggregationFrequency,
    isRelaxedModeSelected: this.props.aggregation?.mode === AggregationMode.Relaxed,
  }

  handleFrequencyChange = (value: string) => {
    const selectedFrequency = parseInt(value, 10) as AggregationFrequency
    this.setState({ selectedFrequency })
  }

  handleModeChange = (checked: boolean) => {
    this.setState({ isRelaxedModeSelected: checked })
  }

  onSave = () => {
    const { selectedFrequency, isRelaxedModeSelected } = this.state

    if (!selectedFrequency) {
      this.props.onSave(null)
      return
    }

    const aggregation: Aggregation = {
      frequency: selectedFrequency,
      mode: isRelaxedModeSelected ? AggregationMode.Relaxed : AggregationMode.Strict,
    }

    this.props.onSave(aggregation)
  }

  render() {
    const { block, element } = bindBem('AggregationDialog')
    const { minFrequency } = this.props
    const { selectedFrequency, isRelaxedModeSelected } = this.state

    return (
      <div className={block()}>
        <ActionsBar>
          <Button
            text={EDIT_TITLES_DIALOG.APPLY}
            style="dark"
            onClick={this.onSave}
            size="small"
            className={element('Submit')}
          />
        </ActionsBar>

        <div className={element('Content')}>
          <div className={element('SectionTitle')}>{AGGREGATION_DIALOG.FREQUENCY}</div>
          <RadioGroup
            onChange={this.handleFrequencyChange}
            value={selectedFrequency ? selectedFrequency.toString() : ''}
          >
            <RadioButton value={''}>None</RadioButton>

            {/* API needs to receive Freq.Fri == 55. The other comparison
                is still valid, and the message catalog can stay as-is. */}
            <RadioButton value={Freq.Fri.toString()} disabled={minFrequency < Freq.Mon}>
              {AGGREGATION_LABEL[Freq.Mon]}
            </RadioButton>

            <RadioButton
              value={Freq.Monthly.toString()}
              disabled={minFrequency < Freq.Monthly}
            >
              {AGGREGATION_LABEL[Freq.Monthly]}
            </RadioButton>

            <RadioButton
              value={Freq.Quarterly.toString()}
              disabled={minFrequency < Freq.Quarterly}
            >
              {AGGREGATION_LABEL[Freq.Quarterly]}
            </RadioButton>

            {/* Biannual is defined, but not used by any real series */}
            <RadioButton
              value={Freq.Annually.toString()}
              disabled={minFrequency < Freq.Annually}
            >
              {AGGREGATION_LABEL[Freq.Annually]}
            </RadioButton>
          </RadioGroup>

          <div className={element('SectionTitle')}>{AGGREGATION_DIALOG.MODE}</div>
          <RadioGroup
            onChange={value => this.handleModeChange(value === AggregationMode.Relaxed)}
            value={
              isRelaxedModeSelected ? AggregationMode.Relaxed : AggregationMode.Strict
            }
          >
            <RadioButton value={AggregationMode.Strict}>
              {AGGREGATION_MODES[AggregationMode.Strict]}
            </RadioButton>
            <RadioButton value={AggregationMode.Relaxed}>
              {AGGREGATION_MODES[AggregationMode.Relaxed]}
            </RadioButton>
          </RadioGroup>
        </div>
      </div>
    )
  }
}
