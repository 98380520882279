import { CLIENT } from './config'
import { IServerSeries, isSeries, toDataSeries, toFoundSeries } from '../services/series'
import Axios from 'axios'
import { parseISO } from 'date-fns'
import { toUTC } from '../utils'
import {
  CORRELATION_URL,
  DATABASES_URL,
  LINK_SHARE_URL,
  MATH_URL,
  RECESSION_COUNTRIES_URL,
  RECESSION_URL,
  SERIES_SEARCH_URL,
} from './endpoints'
import { camelize } from '../utils/camelize'
import { ISearchResponse, ISearchResults } from 'global'

export const getRecessionCountries = async (): Promise<string[]> =>
  (await CLIENT.get<{ countries: string[] }>(RECESSION_COUNTRIES_URL)).data.countries

let lastToken = Axios.CancelToken.source()

export const searchSeries = async (
  query: string,
  database: string,
  last_series_id?: string,
  last_score?: number
): Promise<ISearchResults> => {
  lastToken.cancel()
  lastToken = Axios.CancelToken.source()

  const response = await CLIENT.get<ISearchResponse>(SERIES_SEARCH_URL, {
    params: { query, last_series_id, last_score, database },
    cancelToken: lastToken.token,
  })

  return { ...response.data, data: response.data.data.map(toFoundSeries) }
}

const resetToken = () => {
  lastToken.cancel()
  lastToken = Axios.CancelToken.source()
  return lastToken.token
}

export const getSeries = async (
  databaseId: string,
  seriesId: string,
  cancellable: boolean
): Promise<IDataSeries> => {
  const config = cancellable ? { cancelToken: resetToken() } : {}

  const response = await CLIENT.get<IServerSeries>(
    `${DATABASES_URL}/${databaseId}/series/${seriesId}`,
    config
  )
  return toDataSeries(response.data)
}

export const buildMathPayload = (
  payload: TransformationOrSeries,
  aggregation?: Aggregation,
  interpolation = false,
  trendlineStart?: Date,
  trendlineEnd?: Date
): IMathPayload => ({
  payload,
  aggregation: aggregation
    ? {
        frequency: aggregation.frequency,
        mode: aggregation.mode,
      }
    : null,
  interpolation,
  trendlineStart: toUTC(trendlineStart),
  trendlineEnd: toUTC(trendlineEnd),
})

export const getMaths = async (
  payload: TransformationOrSeries,
  aggregation?: Aggregation,
  interpolation = false,
  cancellable = true,
  trendlineStart?: Date,
  trendlineEnd?: Date
): Promise<IDataSeries> => {
  const config = cancellable ? { cancelToken: resetToken() } : {}

  const data = buildMathPayload(
    payload,
    aggregation,
    interpolation,
    trendlineStart,
    trendlineEnd
  )

  const response = await CLIENT.post(MATH_URL, { transformations: [data] }, config)

  return toDataSeries(
    response.data[0],
    isSeries(payload) ? null : payload,
    interpolation,
    aggregation
  )
}

export const getCorrelation = async (
  s1: TransformationOrSeries,
  s2: TransformationOrSeries,
  start_date: Date,
  end_date: Date
): Promise<number> => {
  const data = { s1, s2, start_date, end_date, interpolation: false }
  const response = await CLIENT.post(CORRELATION_URL, data)
  return response.data.correlation
}

type RawRecession = {
  country: string
  start: string
  end: string | null
}

export const getRecessions = async (country: string): Promise<IRecession[]> => {
  const recessions = await CLIENT.get<{ recessions: RawRecession[] }>(
    `${RECESSION_URL}/${country}`
  )

  return recessions.data.recessions.map(r => ({
    country: r.country,
    startDate: parseISO(r.start),
    endDate: r.end ? parseISO(r.end) : null,
  }))
}

export const createLinkShareData = async (): Promise<IShareLinkResponse> => {
  const response = await CLIENT.post(LINK_SHARE_URL)
  return camelize(response.data)
}

export const updateLinkShareData = async (
  linkId: string
): Promise<IShareLinkResponse> => {
  const url = `${LINK_SHARE_URL}/${linkId}`
  const response = await CLIENT.patch(url)
  return camelize(response.data)
}

export const getSharedLinkData = async (linkId: string): Promise<IShareLinkResponse> => {
  const url = `${LINK_SHARE_URL}/${linkId}`
  const response = await CLIENT.get(url)
  return camelize(response.data)
}

export const uploadShareFile = async (
  blob: Blob,
  uploadUrl: string,
  contentType: string,
  gzip = false
) => {
  const headers = {
    'Content-Type': contentType,
    ...(gzip ? { 'Content-Encoding': 'gzip' } : {}),
  }

  return await Axios.put(uploadUrl, blob, { headers })
}
