// eslint-disable max-lines
import * as d3 from 'd3'
import * as fns from 'date-fns'
import * as _ from 'lodash'
import simplify from 'simplify-js'

import { State as SeriesState } from '../store/Series'
import { AggregationFrequency, Frequency } from 'global'

export const COLORS = [
  '#dd3d71',
  '#02b97e',
  '#f68500',
  '#52bfff',
  '#ffdb00',
  '#b332e0',
  '#a0ff91',
  '#472f97',
]
export const PALE_COLORS = [
  '#f1b1c6',
  '#9ad9d2',
  '#fbce99',
  '#cfedff',
  '#fff199',
  '#e1adf3',
  '#d0ffc9',
  '#b5acd5',
]
export const HIGHLIGHT_COLORS = [
  '#ab1f4c',
  '#006e62',
  '#c26900',
  '#1facff',
  '#ccaf00',
  '#8615ad',
  '#61b354',
  '#271563',
]

export interface IServerDataPoint {
  nSeriesData: number
  date: string
}

export interface IServerSeries {
  aggregationType: number // aggregation type. 1: Average, 2: Sum, 3: End of period, 9: Not allowed, 0: None.
  dataPointsCount: number // total no. of datapoints
  dataPoints: IServerDataPoint[]
  dataType: DataType // points' datatype. Values: US$, LocCur, US$/LC, LC/US$, INDEX, Units, %, ?, RATIO, SCALAR.
  databaseName: string
  datetimeLastModified: number // unix timestamp
  description: string // description
  differenceType: number // 0 if data is all positive and things like 100% stack can be applied.
  frequency: number // frequency. Annual: 10, Quarterly: 30, Monthly: 40, Weekly: 51 to 57 - 51 is Monday, Daily: 60.
  geography: string // geocode for countries/reguis/cities
  geography2: string // like above. Used for e.g. currency exchange
  groupName: string // internal group this series belongs to
  magnitude: number // magnitude. 3 for 10e3, 6 for 10e6
  name: string // id of series
  shortSourceName: string
  sourceName: string
  startDate: number // Starts from 1900. Annual: 118 for 2018, Quarterly: 1181 for Q1 of 2018,
  // Monthly: 11801 for Jan 2018, Weekly/Daily: 1180101 for Jan 1, 2018
  startingPeriod: number
  weekly: string // weekly indicator. W if data is weekly or " "
  decimalPrecision: number
  originalFrequency: number
}

export interface ISearchServerSeries {
  db_name: string
  description: string
  score: number
  series_name: string
}

export enum Freq {
  Decennial = 7,
  Quinquennial = 8,
  BiYearly = 9,
  Annually = 10,
  Quarterly = 30,
  Monthly = 40,
  Mon = 51,
  Tue = 52,
  Wen = 53,
  Thu = 54,
  Fri = 55,
  Sat = 56,
  Sun = 57,
  Daily = 60,
}

export enum AggregationMode {
  Strict = 'strict',
  Relaxed = 'relaxed',
}

export const AGG_NOT_ALLOWED = 9

export const LINE_TYPES: ISeriesType[] = [
  'LINE',
  'AREA',
  'STEPLINE',
  'DOTTEDLINE',
  'DASHEDLINE',
]

export const transformationTypes = ['ADD', 'SUBTRACT', 'MULTIPLY', 'DIVIDE', 'PERCENT']

export enum transformationTypeSigns {
  ADD = '+',
  SUBTRACT = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
  PERCENT = '%',
}

export const parseMtz = (mtzNum: number, frequency: Frequency): Date => {
  // mtz format is YYY[(Q|MM)][DD], starts from 1900, variable frequency.
  // E.g.: 118 for 2018, 1181 for Q1 of 2018, 11801 for Jan 2018, Weekly/Daily: 1180101 for Jan 1, 2018
  let mtz = mtzNum.toString()
  let [year, month, day] = [1900, 0, 1]
  if (frequency > Freq.Monthly) {
    day = parseInt(mtz.substr(-2, 2), 10)
    mtz = mtz.slice(0, -2)
  }
  if (frequency >= Freq.Monthly) {
    month = parseInt(mtz.substr(-2, 2), 10) - 1
    mtz = mtz.slice(0, -2)
  }
  if (frequency === Freq.Quarterly) {
    month = (parseInt(mtz.substr(-1, 1), 10) - 1) * 3
    mtz = mtz.slice(0, -1)
  }
  year += parseInt(mtz, 10)
  return new Date(year, month, day)
}

function isValueNull(value: number) {
  return value === null || value === 1e9
}

function round(value: number, precision: number) {
  // a value with 7 digits or more can cause rounding issues
  if (Math.log10(Math.abs(value)) + precision > 7) {
    return value
  }
  return Math.fround(value)
}

const getDataPoints = (
  points: IServerDataPoint[],
  frequency: Frequency,
  precision: number
): IDataPoint[] => {
  return points.map(({ date, nSeriesData }) => {
    let currentDate = fns.parseISO(date)
    if (frequency !== Freq.Daily) {
      currentDate = getMiddleDate(currentDate, frequency)
    }
    currentDate.setHours(0, 0, 0)
    const elem = {
      date: currentDate,
      value: isValueNull(nSeriesData) ? null : round(nSeriesData, precision),
    }
    return elem
  })
}

export const getAggregation = (s: IDataSeries): Aggregation | null => s.appliedAggregation

export const toDataSeries = (
  series: IServerSeries,
  transformation?: ITransformation,
  isInterpolated = false,
  aggregation?: Aggregation | null
): IDataSeries => {
  const frequency: number = (aggregation?.frequency || series.frequency) as Frequency
  const originalFrequency = series.originalFrequency as Frequency
  const startDate = parseMtz(series.startDate, frequency)
  const sortedData = _.sortBy(series.dataPoints || [], 'date')
  const dataPoints = getDataPoints(sortedData, frequency, series.decimalPrecision)

  const result: IDataSeries = {
    id: series.name,
    description: series.description,
    title: series.description,
    databaseId: series.databaseName,
    sourceName: series.sourceName,
    frequency: frequency,
    shortSourceName: series.shortSourceName,
    startDate,
    dataPoints,
    colorIndex: null,
    decimalPrecision: series.decimalPrecision,
    transformation,
    differenceType: series.differenceType === 1,
    aggregationType: series.aggregationType,
    appliedAggregation: aggregation,
    dataType: series.dataType,
    geography: series.geography,
    geography2: series.geography2,
    groupName: series.groupName,
    lastModified: new Date(series.datetimeLastModified * 1000),
    magnitude: series.magnitude,
    isInterpolated,
    originalFrequency,
    offset: 0,
    axisAssignment: null,
    dataMarkers: false,
    hasDataLabels: false,
    uuid: null,
    isFrozen: false,
  }
  return result
}

export const toFoundSeries = (s: ISearchServerSeries): IFoundSeries => {
  return {
    id: s.series_name,
    description: s.description,
    databaseId: s.db_name,
  }
}

export const formatLabelAnnually = (date: Date) => {
  return fns.format(date, 'yyyy')
}

export const formatLabelQuarterly = (date: Date) => {
  return fns.format(date, 'yyyy QQQ').toUpperCase()
}

export const formatLabelMonthly = (date: Date) =>
  fns.format(date, 'MMM yyyy').toUpperCase()

export const formatLabelDaily = (date: Date) => {
  return fns.format(date, 'd MMM yyyy').toUpperCase()
}

export const formatLabel = (date: Date, frequency: Frequency) => {
  if (frequency <= Freq.Annually) {
    return formatLabelAnnually(date)
  }
  if (frequency === Freq.Quarterly) {
    return formatLabelQuarterly(date)
  }
  if (frequency === Freq.Monthly) {
    return formatLabelMonthly(date)
  }
  return formatLabelDaily(date)
}

export const getScaledFrequency = (
  pxPerMonth: number,
  includeMonthly: boolean
): Frequency => {
  switch (true) {
    case pxPerMonth > 7 && includeMonthly:
      return Freq.Monthly
    case pxPerMonth > 3.3:
      return Freq.Annually
    case pxPerMonth > 1.5:
      return Freq.BiYearly
    case pxPerMonth > 0.65:
      return Freq.Quinquennial
    default:
      return Freq.Decennial
  }
}

export const freqToInterval = (f: Frequency) => {
  const a: { [index: number]: d3.TimeInterval } = {
    [Freq.Daily]: d3.timeDay,
    [Freq.Mon]: d3.timeMonday,
    [Freq.Tue]: d3.timeTuesday,
    [Freq.Wen]: d3.timeWednesday,
    [Freq.Thu]: d3.timeThursday,
    [Freq.Fri]: d3.timeFriday,
    [Freq.Sat]: d3.timeSaturday,
    [Freq.Sun]: d3.timeSunday,
    [Freq.Quarterly]: d3.timeMonth.every(3),
    [Freq.Monthly]: d3.timeMonth,
    [Freq.Annually]: d3.timeYear,
    [Freq.BiYearly]: d3.timeYear.every(2),
    [Freq.Quinquennial]: d3.timeYear.every(5),
    [Freq.Decennial]: d3.timeYear.every(10),
  }
  return a[f] || d3.timeWeek
}

export const pxPerMonths = (startDate: Date, endDate: Date, width: number) => {
  const diffMonths = (endDate.valueOf() - startDate.valueOf()) / 1000 / 3600 / 24 / 30
  return width / diffMonths
}

const isDatapointInRange = (datapoint: Date, position: Date, f: Frequency) => {
  const freq = freqToInterval(f)
  const nextDate = freq.offset(datapoint)
  return (
    Math.abs(fns.differenceInDays(datapoint, position)) <
    Math.abs(fns.differenceInDays(nextDate, datapoint))
  )
}

export const getDataPoint = (dataPoints: IDataPoint[], date: Date, f: Frequency) => {
  const bisect = d3.bisector((d: IDataPoint) => d.date).left
  const index = bisect(dataPoints as any, date, 1)
  if (index > dataPoints.length) {
    return null
  }
  const dataPoint = _.minBy(dataPoints.slice(index - 1, index + 1), d =>
    Math.abs(d.date.valueOf() - date.valueOf())
  )
  return isDatapointInRange(dataPoint.date, date, f) ? dataPoint : null
}

const flooredTo = (n: number, k: number) => (n % k === 0 ? n : n - (n % k))

export const getMiddleDate = (d: Date, f: Frequency) => {
  switch (f) {
    case Freq.Decennial:
      return fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 10) + 5)
    case Freq.Quinquennial:
      return fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 5))
    case Freq.BiYearly:
      return fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 2) + 1)
    case Freq.Annually:
      return fns.addMonths(fns.startOfYear(d), 6)
    case Freq.Quarterly:
      return fns.addDays(fns.addMonths(fns.startOfQuarter(d), 1), 16)
    case Freq.Monthly:
      return fns.addDays(fns.startOfMonth(d), 16)
    case Freq.Daily:
      return fns.addHours(fns.startOfDay(d), 12)
    default:
      const weekday = f - 50
      return fns.addDays(fns.startOfWeek(d), weekday)
  }
}

export const getStartDate = (d: Date, f: Frequency) => {
  switch (f) {
    case Freq.Decennial:
      return fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 10))
    case Freq.Quinquennial:
      return fns.addMonths(
        fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 5) + 2),
        6
      )
    case Freq.BiYearly:
      return fns.setYear(fns.startOfYear(d), flooredTo(d.getFullYear(), 2))
    case Freq.Annually:
      return fns.startOfYear(d)
    case Freq.Quarterly:
      return fns.startOfQuarter(d)
    case Freq.Monthly:
      return fns.startOfMonth(d)
    case Freq.Daily:
      return fns.startOfDay(d)
    default:
      const weekday = f - 50
      return fns.addDays(fns.startOfWeek(d), weekday)
  }
}
export const getEndDate = (d: Date, f: Frequency) => {
  switch (f) {
    case Freq.Annually:
      return fns.endOfYear(d)
    case Freq.Quarterly:
      return fns.endOfQuarter(d)
    case Freq.Monthly:
      return fns.endOfMonth(d)
    case Freq.Daily:
      return fns.endOfDay(d)
    default:
      const weekday = f - 50
      return fns.addDays(fns.endOfWeek(d), weekday)
  }
}

export const getDefaultTimeSpan = (f: Frequency) => {
  if (f <= Freq.Monthly) {
    return 11
  }
  if (f < Freq.Daily) {
    return 4
  }
  return 1
}

export const getMaxFrequency = (variables: IDataSeries[]) =>
  Math.max(...variables.map(v => v.frequency)) as Frequency

export const getMinFrequency = (variables: IDataSeries[]) =>
  Math.min(...variables.map(v => v.originalFrequency)) as Frequency

export const haveSameFrequency = (variables: IDataSeries[]) => {
  const frequencies = variables.map(series => {
    if (isWeekly(series.frequency)) {
      return Freq.Mon
    }
    return series.frequency
  })
  return _.without(frequencies, frequencies[0]).length === 0
}

export const simplifyDataPointsLTTB = (datapoints: RichDataPoint[]) =>
  simplify(datapoints, 0.2, false)

export const getBarSamplingRatio = (datapoints: RichDataPoint[]) => {
  if (datapoints.length === 0) {
    return 1
  }
  const width = _.last(datapoints).x - _.first(datapoints).x
  const ratio = Math.ceil((datapoints.length / width) * 6)
  return ratio
}

export function getSumExent(series: IDataSeries[], fn: (r: IDataPoint) => Date | number) {
  series = series.filter(s => s.dataPoints.length > 0)
  if (series.length === 0) {
    return [0, 0]
  }
  const extents = series
    .map(s => d3.extent(s.dataPoints, fn))
    .filter(x => !x.includes(undefined))
  if (extents.length === 0) {
    return [0, 0]
  }
  return [_.minBy(extents, '0')[0], _.maxBy(extents, '1')[1]]
}

export const isWeekly = (freq: Frequency) => freq > Freq.Monthly && freq < Freq.Daily

export const canApplyStackedBar = (
  series: IDataSeries,
  graphTypes: ISeriesType[],
  variables: IDataSeries[]
) => {
  const index = Math.max(
    ...['STACKEDBAR', 'SHADED_STACKEDBAR'].map((type: ISeriesType) =>
      graphTypes.indexOf(type)
    )
  )
  if (index === -1) {
    return true
  }
  const stackedFreq = variables[index].frequency
  return (
    isWeekly(stackedFreq) === isWeekly(series.frequency) ||
    stackedFreq === series.frequency
  )
}

export const graphTypeForNewSeries = (
  series: IDataSeries,
  graphTypes: ISeriesType[],
  variables: IDataSeries[]
) => {
  const shouldInheritType = graphTypes.length > 0 && !hasTrendLine(series)
  const graphType = shouldInheritType ? graphTypes[0] : 'LINE'

  if (graphType === 'STACKEDBAR' && !canApplyStackedBar(series, graphTypes, variables)) {
    return 'LINE'
  }
  return graphType
}

export const dataMarkersAvailability = {
  LINE: true,
  BAR: false,
  SHADED_BAR: false,
  AREA: false,
  STEPLINE: true,
  DOTTEDLINE: true,
  DASHEDLINE: true,
  STACKEDBAR: false,
  SHADED_STACKEDBAR: false,
  SCATTER: false,
}

export const dataLabelsAvailability = {
  LINE: true,
  BAR: false,
  SHADED_BAR: false,
  AREA: false,
  STEPLINE: true,
  DOTTEDLINE: true,
  DASHEDLINE: true,
  STACKEDBAR: false,
  SHADED_STACKEDBAR: false,
  SCATTER: false,
}

export const globalDataMarkers = (
  variables: IDataSeries[],
  graphTypes: ISeriesType[]
) => {
  const validVariables = variables.filter(
    (_s, i) => dataMarkersAvailability[graphTypes[i]] === true
  )
  const variablesWithDataMarkers = validVariables.filter(series => series.dataMarkers)
  return (
    validVariables.length > 0 && variablesWithDataMarkers.length === validVariables.length
  )
}

export const globalDataLabels = (variables: IDataSeries[], graphTypes: ISeriesType[]) => {
  const validVariables = variables.filter(
    (_s, i) => dataLabelsAvailability[graphTypes[i]] === true
  )
  const variablesWithDataLabels = validVariables.filter(series => series.hasDataLabels)
  return (
    validVariables.length > 0 && variablesWithDataLabels.length === validVariables.length
  )
}

export function isSeries(t: TransformationArg): t is ISingleSeries {
  return typeof t === 'object' && (t as ISingleSeries).seriesId !== undefined
}

type ArithmeticRenderMode = 'infix' | 'postfix'

export const getSeriesLabel = (
  series: IDataSeries,
  arithmeticRenderMode: ArithmeticRenderMode = 'infix'
): string => {
  let label = series.transformation
    ? getTransformationLabel(series.transformation, arithmeticRenderMode)
    : `${series.id}@${series.databaseId}`
  if (series.isInterpolated) {
    label += '[I]'
  }
  if (series.offset) {
    label += `[${series.offset < 0 ? '-' : '+'}${Math.abs(series.offset)}]`
  }
  return label
}

export const getSeriesHeader = (s: IDataSeries) =>
  s.transformation || s.offset ? getSeriesLabel(s) : `${s.id}@${s.databaseId}`

export const getTransformationLabel = (
  transformation: TransformationOrSeries,
  arithmeticRenderMode: ArithmeticRenderMode = 'infix'
): string => {
  if (isSeries(transformation)) {
    return `${transformation.seriesId}@${transformation.databaseId}`
  }
  const args = transformation.args.map(arg => {
    if (typeof arg === 'string' || typeof arg === 'number') {
      return arg.toString()
    }
    return getTransformationLabel(arg, arithmeticRenderMode)
  })
  if (
    arithmeticRenderMode === 'infix' &&
    transformationTypes.includes(transformation.func) &&
    args.length > 1
  ) {
    // eslint-disable-next-line
    // @ts-ignore
    return `(${args[0]} ${transformationTypeSigns[transformation.func]} ${args[1]})`
  }
  return `${transformation.func}(${args.join(',')})`
}

export const hasTrendLine = (series: IDataSeries) => {
  return series.transformation?.func === 'TRNDLN'
}

export const hasZScore = (series: IDataSeries) => {
  // FIXME: bit of a perf-disaster
  return JSON.stringify(series.transformation || '').includes('"func":"zs"')
}

export const canApplyDataLabels = (graphType: ISeriesType) =>
  LINE_TYPES.includes(graphType)

export const isFrequencyMismatch = (f1: Frequency, f2: Frequency) =>
  f1 !== f2 && !(isWeekly(f1) && isWeekly(f2))

export const dumpSeriesState = (series: SeriesState): ISeriesDump => {
  const endZoomDate =
    series.seriesSettings.endZoomDate ||
    _.max(
      series.variables.map(v =>
        v.dataPoints.length === 0 ? null : _.last(v.dataPoints).date
      )
    )
  return {
    variables: series.variables,
    seriesSettings: { ...series.seriesSettings, endZoomDate },
    correlation: series.correlation,
    scatterSettings: series.scatterSettings,
    scale: series.scale,
    trendlineBoundaries: series.trendlineBoundaries,
  }
}

export const isCorrLabelShown = (variables: IDataSeries[], correlation: ICorrelation) =>
  correlation?.enabled &&
  variables.length > 1 &&
  typeof correlation.value === 'number' &&
  !isFrequencyMismatch(variables[0].frequency, variables[1].frequency)

export const getSeriesTitles = (variables: IDataSeries[], customTitles: string[]) =>
  variables.map((series, index) => customTitles[index] || series.title)

export const getScatterTitles = (variables: IDataSeries[], customTitles: string[]) => {
  const [t1, t2] = customTitles
  const [v1, v2] = variables

  return [`X-Axis: ${t1 || v1.title}`, `Y-Axis: ${t2 || v2.title}`]
}

export const isLogScaleDisabled = (yAxisType: YAxisType, variables: IDataSeries[]) => {
  const allAssigments = seriesToAssigments(variables)
  const badAssigments: Set<AxisAssignment> = seriesToAssigments(
    variables.filter(shouldLogBeDisabledOn)
  )
  return (
    yAxisType !== 'log' &&
    (variables.length === 0 || _.isEqual(allAssigments, badAssigments))
  )
}

const shouldLogBeDisabledOn = (s: IDataSeries) => {
  return s.differenceType || s.dataPoints.some(d => d.value <= 0)
}

const seriesToAssigments = (variables: IDataSeries[]): Set<AxisAssignment> => {
  return variables.reduce(
    (acc, s) => acc.add(s.axisAssignment),
    new Set<AxisAssignment>()
  )
}
